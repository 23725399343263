<template>
    <v-content>
      <div style="position: absolute; top: 20px; right: 20px; cursor: pointer;z-index: 1;" onclick="parent.postMessage('close', '*')">
        <v-icon large color="black" style="font-size: 25px; text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;">close</v-icon>
      </div>
        <v-container :style="`background-color:${config.background_color} !important; height: 100%`">
            <v-row justify="center">
                <v-col cols="12" align="center" :style="`padding-top:35px`">
                    <h4 style="font-size: 22px;" :style="`color:${config.mfontcolor} !important`">{{ config.line1 }}</h4>
                </v-col>
            </v-row>
            <v-row justify="center" class="layout_login_image_container">
                <v-col cols="12" align="center">
                    <v-img :src="config.layout_login_image" :width="(config.layout_login_image_width == 100) ? '100%' : config.layout_login_image_width" height="auto" contain></v-img>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" align="center">
                    <h4 :style="`color:${config.mfontcolor} !important`">{{ config.line2 }}</h4>
                </v-col>
            </v-row>
            <v-row justify="center" class="back-btn">
                <v-btn
                        text
                        class="border0px pad2px"
                        small
                        :color="config.mcolor"
                        :style="`color:${config.mcolor} !important`"
                        @click="dosociallogin"
                >
                    <v-icon :style="`font-size: 16px;color: ${config.main_color}!important;`">mdi-arrow-left</v-icon>
                    {{$t('LoginScreen.BACK_BTN')}}
                </v-btn>
                <v-col cols="12" align="center" justify="center" class="pb-16">
                    <v-card>
                        <v-tabs
                                :color="config.mfontcolor"
                                :background-color="config.mcolor"
                                height="60px"
                                centered
                                fixed-tabs
                                @change="resetforms"
                        >
                            <v-tab ripple v-text="$t('LoginScreen.LOGIN')"></v-tab>
                            <v-tab ripple v-text="$t('LoginScreen.REGISTER')"></v-tab>
                            <v-tab-item>
                                <v-card flat elevation="n-7" :style="`background-color:${config.pcolor} !important; border-radius: 0`" :border-radius="0">
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" sm12 md12>
                                                <v-form v-model="login.valid" ref="loginform">

                                                    <alert
                                                            :errorMessage="login.errors"
                                                            :alert="login.showerror"
                                                    ></alert>

                                                    <v-text-field
                                                            solo
                                                            :label="$t('LoginScreen.PHONE_FIELD')"
                                                            name="loginemail"
                                                            prepend-icon="mdi-phone"
                                                            type="text"
                                                            v-model="login.phone"
                                                            autocomplete="off"
                                                            :rules="[
                                                              value => {
                                                                return usernamerule(value);
                                                              }
                                                            ]"
                                                    ></v-text-field>

                                                    <v-text-field
                                                            solo
                                                            id="loginpassword"
                                                            :label="$t('LoginScreen.PASSWORD')"
                                                            name="loginpassword"
                                                            prepend-icon="lock"
                                                            :append-icon="login.show1 ? 'visibility' : 'visibility_off'"
                                                            @click:append="login.show1 = !login.show1"
                                                            :type="login.show1 ? 'text' : 'password'"
                                                            v-model="login.password"
                                                            :rules="[
                                                              value => {
                                                                return passwordrule(value);
                                                              }
                                                            ]"
                                                    ></v-text-field>

                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-btn
                                                                    block
                                                                    class="loginbtn"
                                                                    :color="config.mcolor"
                                                                    :style="`color:${config.mfontcolor}`"
                                                                    @click="doLogin"
                                                                    :loading="login.loading"
                                                                    :disabled="login.loading"
                                                                    v-text="$t('LoginScreen.LOGIN_BTN')"
                                                            ></v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-form>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                                text
                                                small
                                                class="border0px pad2px"
                                                :color="config.forgotbg"
                                                :style="`color:${config.mfontcolor} !important; width: 100%`"
                                                v-text="$t('LoginScreen.FORGOT_PASSWORD')"
                                                @click="changepassword"
                                        ></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card flat elevation="n-7" :style="`background-color:${config.pcolor} !important; border-radius: 0`" :border-radius="0">
                                    <v-card-text>
                                        <v-row>
                                            <v-col xs12>
                                                <v-form v-model="register.valid" ref="registerform">

                                                    <alert
                                                            :errorMessage="register.errors"
                                                            :alert="register.showerror"
                                                            :errorType="register.errType"
                                                    ></alert>

                                                    <v-text-field
                                                            solo
                                                            v-if="!register.confirmationcodesent"
                                                            id="regpassword"
                                                            :label="$t('LoginScreen.NAME')"
                                                            name="regfullname"
                                                            prepend-icon="person"
                                                            type="text"
                                                            autocomplete="off"
                                                            v-model="register.fullname"
                                                            :rules="[
                                                              value => {
                                                                return regfullnamerule(value);
                                                              }
                                                            ]"
                                                    ></v-text-field>
                                                    <v-text-field
                                                            solo
                                                            v-if="!register.confirmationcodesent"
                                                            :label="$t('LoginScreen.PHONE_FIELD')"
                                                            name="registerusername"
                                                            prepend-icon="mdi-phone"
                                                            type="text"
                                                            v-model="register.phone"
                                                            autocomplete="off"
                                                            :rules="[
                                                              value => {
                                                                return usernamerule(value);
                                                              }
                                                            ]"
                                                    ></v-text-field>

                                                    <v-text-field
                                                            solo
                                                            v-if="!register.confirmationcodesent"
                                                            :label="$t('LoginScreen.PASSWORD')"
                                                            name="registerusername"
                                                            prepend-icon="lock"
                                                            v-model="register.password"
                                                            :append-icon="login.show1 ? 'visibility' : 'visibility_off'"
                                                            @click:append="login.show1 = !login.show1"
                                                            :type="login.show1 ? 'text' : 'password'"
                                                            autocomplete="off"
                                                            :rules="[
                                                              value => {
                                                                return passwordrule(value);
                                                              }
                                                            ]"
                                                    ></v-text-field>

                                                  <span v-if="config.invitation_enabled">
                                                    <label v-if="showInvitationFieldLabel && register.invitecode" for="logininvitecode" :style="`color:${config.mfontcolor} !important`">
                                                      {{ getInvitationCodeText() }}
                                                    </label>
                                                    <v-text-field
                                                        solo
                                                        v-if="!register.confirmationcodesent"
                                                        id="logininvitecode"
                                                        :label="getInvitationCodeText()"
                                                        name="logininvitecode"
                                                        prepend-icon="share"
                                                        type="text"
                                                        autocomplete="off"
                                                        v-model="register.invitecode"
                                                        :rules="[
                                                          value => {
                                                            return registerinviterule(value);
                                                          }
                                                        ]"
                                                    ></v-text-field>
                                                  </span>


                                                    <v-text-field
                                                            solo
                                                            v-if="register.confirmationcodesent"
                                                            id="loginsmscode"
                                                            :label="$t('LoginScreen.SMS_CODE')"
                                                            name="registerconfirmationcode"
                                                            prepend-icon="sms"
                                                            type="text"
                                                            autocomplete="off"
                                                            v-model="register.confirmationcode"
                                                    ></v-text-field>

                                                    <v-checkbox
                                                            solo
                                                            v-if="!register.confirmationcodesent"
                                                            dense
                                                            class="mx-3"
                                                            v-model="register.agree"
                                                            :rules="[
                                                              value => {
                                                                return registerAgreerule(value);
                                                              }
                                                            ]"
                                                    >
                                                        <template v-slot:label>
                                                            <div class="condition">
                                                                <span v-text="'* ' + $t('LoginScreen.IAGREE')" :style="`color:${config.mfontcolor} !important`"></span>
                                                                <!--<v-tooltip bottom>
                                                                    <template v-slot:activator="{ on }">-->
                                                                        <a :style="`color:${config.mcolor} !important`"
                                                                                @click="showRulesDialog"
                                                                                v-on="on"
                                                                                v-text="$t('LoginScreen.TERMS')"
                                                                        ></a>
                                                                    <!--</template>
                                                                </v-tooltip>-->
                                                            </div>
                                                        </template>
                                                    </v-checkbox>
                                                </v-form>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                                block
                                                :color="config.mcolor"
                                                :style="`color:${config.mfontcolor}`"
                                                @click="doRegister"
                                                v-text="$t('LoginScreen.REGISTER')"
                                                :loading="register.loading"
                                                :disabled="register.loading"
                                        ></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-tab-item>
                        </v-tabs>
                    </v-card>
                </v-col>
            </v-row>
            <v-dialog v-model="dialog.show" fullscreen>
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="dialog.show = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title v-text="$t('LoginScreen.RULES_TITLE')"></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text v-html="dialog.content"></v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                :color="config.pcolor"
                                :style="`color:${config.pfontcolor} !important`"
                                text
                                @click="agreeRules"
                                v-text="$t('LoginScreen.ACCEPT_BTN')"
                        ></v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-footer
                absolute
                :style="`position:fixed;`"
                >
                <div style="float:left;font-size: 13px;width: 50%" id="footer-left">
                    Powered by
                    <a :href="poweredUrl" class="extarnal animated-hover" style="border-bottom: 2px dotted #323d48;text-decoration: none;" target="_blank" v-text="poweredCompany"></a>

                </div>
                <div class="clr"></div>
                <div style="float:right;width: 50%;font-size: 13px;text-align: right;" id="footer-right" v-text="companyData.name"></div>
                <div class="clr"></div>
            </v-footer>
        </v-container>
    </v-content>
</template>

<script>
    import {
        LOGIN_SUCCESS,
        LOGIN_FAILED,
        INCORRECT_EMAIL_FORMAT,
        PASSWORD_IS_REQUIRED,
        INCORRECT_EMAIL_OR_PASSWORD,
        CREATED_ACCOUNT_SUCCESSFULLY,
        SMS_SENT,
        UNABLE_TO_CREATE_ACCOUNT,
        NO_COMPANY_FOUND,
        COMPANY_URL_IS_REQUIRED,
        EMAIL_IS_REQUIRED,
        NAME_IS_REQUIRED,
        ACCOUNT_ALREADY_EXISTS,
        UNABLE_TO_VALIDATE_ACCOUNT
    } from "@/appConstants";
    import Vue from "vue";
    import loginapi from "@/services/loginapi";
    import fapi from "@/services/fetchapi";
    import token from "@/core/auth";
    import companyconfig from "@/core/companyconfig";
    import alert from "@/components/shared/erroralert";
    export default {
        data() {
            return {
                login: {
                    valid: true,
                    phone: "",
                    password: "",
                    errors: "",
                    showerror: false,
                    show1: false,
                    loading: false
                },
                config: {},
                //pconfig: {},
                register: {
                    password: "",
                    valid: true,
                    phone: "",
                    fullname: "",
                    invitecode: Vue.localStorage.get('ref' + "_" + this.$cn),
                    errors: "",
                    showerror: false,
                    agree: true,
                    errType: "error",
                    loading: false,
                    confirmationcode: "",
                    confirmationcodesent: false
                },
                dialog: {
                    show: false,
                    content: ""
                },
                showsocialLogin: true,
                showInvitationFieldLabel: false,
                companyData: companyconfig.getCompanyScheme(),
              poweredCompany: companyconfig.getCompanyScheme().partner_name, //=== false ? '2take.it' : 'Blovly',
                poweredUrl: companyconfig.getCompanyScheme().pbl
            };
        },
        created() {
            this.setupLayout();
            let passedCompanyId = companyconfig.getCompanyIdfromUrl();
            if (token.isLoggedIn()) {
                this.$router.push({
                    name: "rules",
                    query: { company_name: passedCompanyId }
                });
            }
            //Setup invitation code passed from URL
            if (companyconfig.getInvitationCode(passedCompanyId)) {
              this.showInvitationFieldLabel = true;
              this.register.invitecode = companyconfig.getInvitationCode(passedCompanyId);
            }
        },
        methods: {
            /** Show the Rules dialod on clicking the agree button */
            showRulesDialog() {
                this.dialog.show = true;
            },
            /** Agree the rules */
            agreeRules() {
                this.dialog.show = false;
                this.register.agree = true;
            },
            /**
             * Set the Intial color configuration for page
             */
            setupLayout() {
                let data = companyconfig.getCompanyScheme();
                if (data != "") {
                    Vue.set(this.config, "logo", data.logo_image);
                    Vue.set(this.config, "layout_login_image", data.layout_login_image);
                    Vue.set(this.config, "layout_login_image_width", data.layout_login_image_width);
                    Vue.set(this.config, "loginbg", data.Login_btn_bg);
                    Vue.set(this.config, "logincolor", data.Login_btn_color);
                    Vue.set(this.config, "cancelbg", data.Cancel_btn_bg);
                    Vue.set(this.config, "cancelcolor", data.Cancel_btn_color);
                    Vue.set(this.config, "forgotbg", data.Forgot_btn_bg);
                    Vue.set(this.config, "forgotcolor", data.Forgot_btn_color);
                    Vue.set(this.config, "logintabbg", data.Login_Tab_bg);
                    Vue.set(this.config, "logintabcolor", data.Login_Tab_color);
                    Vue.set(this.config, "registerbg", data.Register_btn_bg);
                    Vue.set(this.config, "registercolor", data.Register_btn_color);
                    Vue.set(this.config, "line1", data.login_text_1_line);
                    Vue.set(this.config, "line2", data.login_text_2_line);
                    Vue.set(this.dialog, "content", data.company_rules);
                    Vue.set(this.config, "mcolor", data.main_color);
                    Vue.set(this.config, "mfontcolor", data.main_font_color);
                    Vue.set(this.config, "pcolor", data.primary_color);
                    Vue.set(this.config, "pfontcolor", data.primary_font_color);
                    Vue.set(this.config, "background_color", data.background_color);
                    Vue.set(this.config, "invitation_enabled", data.invitation_enabled);
                    Vue.set(this.config, "invite_code_required", data.invite_code_required);
                    this.showsocialLogin = !data.only_email_login;
                }
            },
            /** Login Username validation */
            usernamerule(value) {
                const regexp = /^\d{9,}$/;
                value = value.replace('+', "");
                this.login.phone = value;

                if (!value) {
                    return this.$t("LoginScreen.PHONE_REQUIRED");
                } else if (!regexp.test(value)) {
                    return this.$t("LoginScreen.PHONE_VALID");
                }
                return true;
            },
            /** Login Password validation */
            passwordrule(value) {
                if (!value) {
                    return this.$t("LoginScreen.PASSWORD_REQUIRED");
                }
                return true;
            },
            /** Register user full name validation */
            regfullnamerule(value) {
                if (!value) {
                    return this.$t("LoginScreen.NAME_REQUIRED");
                }
                return true;
            },
            /** Register mandatory agree check validation */
            registerAgreerule(value) {
                if (!value) {
                    return this.$t("LoginScreen.MUST_AGREE_VALID");
                }
                return true;
            },
          registerinviterule(value) {
            if (this.config.invite_code_required === '1' && (value == null || value.length < 1)) {
              return this.$t("LoginScreen.INVITECODE_REQUIRED_MESSAGE");
            }
            return true;
          },
            /** Reset the forms on tab change */
            resetforms() {
                if (this.$refs.loginform) {
                    this.$refs.loginform.reset();
                }
                if (this.$refs.registerform) {
                    this.register.valid = true;
                    this.register.username = "";
                    this.register.fullname = "";
                    this.register.invitecode = "";
                    this.register.agree = true;
                    this.$refs.registerform.resetValidation();
                }
            },
            /** Redirect to change password screen */
            changepassword() {
                this.$router.push({
                    name: "forgotphonepassword"
                });
            },
            /** Redirect to social login screen */
            dosociallogin() {
              let data = companyconfig.getCompanyScheme();

              if(data.use_simple_login === '1') {
                this.$router.push({
                  name: "simplelogin"
                });
              } else {
                this.$router.push({
                  name: "login"
                });
              }

            },
            /** Validate and do the login action */
            doLogin() {
                let isvalid = this.$refs.loginform.validate();
                this.login.valid = isvalid;
                let passedCompanyId = companyconfig.getCompanyIdfromUrl();
                let isLegacyMode = companyconfig.isLegacyMode(passedCompanyId);
                if (isvalid) {
                    this.login.showerror = false;
                    this.login.loading = true;

                  let guest_hash = localStorage.getItem('guest_hash');
                  if(guest_hash) {
                    let googletag = typeof window.gtag !== 'undefined' ? window.gtag : undefined;
                    loginapi.setGuestHash(guest_hash, passedCompanyId, googletag);
                  }

                    loginapi
                        .doSMSLogin(
                            this.login.phone,
                            this.login.password,
                            passedCompanyId,
                            isLegacyMode
                        )
                        .then(response => {
                            let self = this;
                            let accesstkn = response.data;
                            let msg = response.data.message;
                            if (msg === LOGIN_SUCCESS && response.data.url && isLegacyMode) {
                                companyconfig.clearInvitationCode(passedCompanyId);
                                this.goLegacyUrl(response.data.url);
                                return;
                            }
                            if (msg === LOGIN_SUCCESS && accesstkn.access_token) {
                                token.setTokens(
                                    accesstkn.access_token,
                                    accesstkn.refresh_token,
                                    accesstkn.expiry_second
                                );
                                let lang = this.$i18n.locale;
                                fapi
                                    .hasAnyRules(accesstkn.access_token, passedCompanyId, lang)
                                    .then(function(response) {
                                        if (response === true) {
                                            self.$router.push({
                                                name: "rules"
                                            });
                                        } else {
                                            self.$router.push({
                                                name: "home"
                                            });
                                        }
                                        self.login.loading = false;
                                    })
                                    .catch(() => {
                                        this.$router.push({
                                            name: "home"
                                        });
                                        this.login.loading = false;
                                    });
                            } else {
                                this.login.errors = this.$t("Errors.INCORRECT_EMAIL_OR_PASSWORD");
                                this.login.showerror = true;
                                this.login.loading = false;
                            }
                        })
                        .catch(response => {
                            let msg = response.data.message;
                            if (msg === LOGIN_FAILED) {
                                let errormsg = response.data.error;
                                this.login.errors = this.$t("LoginScreen.INCORRECT_PHONE_OR_PASSWORD");
                                if (
                                    errormsg.email &&
                                    errormsg.email[0] === INCORRECT_EMAIL_FORMAT
                                ) {
                                    this.login.errors = this.$t("Errors.INCORRECT_EMAIL_FORMAT");
                                } else if (
                                    errormsg.password &&
                                    errormsg.password[0] === PASSWORD_IS_REQUIRED
                                ) {
                                    this.login.errors = this.$t("Errors.PASSWORD_IS_REQUIRED");
                                } else if (
                                    errormsg.company_url &&
                                    errormsg.company_url[0] === COMPANY_URL_IS_REQUIRED
                                ) {
                                    this.login.errors = this.$t("Errors.COMPANY_URL_IS_REQUIRED");
                                } else if (
                                    errormsg.password &&
                                    errormsg.password[0] === INCORRECT_EMAIL_OR_PASSWORD
                                ) {
                                    this.login.errors = this.$t(
                                        "Errors.INCORRECT_EMAIL_OR_PASSWORD"
                                    );
                                }
                            } else {
                                this.login.errors = this.$t("Errors.INCORRECT_EMAIL_OR_PASSWORD");
                            }
                            //this.login.errors = this.$t("LoginScreen.LOGIN_FAILED");
                            this.login.showerror = true;
                            this.login.loading = false;
                        });
                }
            },
            /** Reset the login screen */
            cancelLogin() {
                this.resetforms();
            },
            /** Reset the register screen */
            cancelRegister() {
                this.resetforms();
            },
            /** Validate and do the registration which will prompt an email */
            doRegister() {
                let isvalid = this.$refs.registerform.validate();
                this.register.valid = isvalid;
                let instance = this;
                if (isvalid) {
                    this.register.showerror = false;
                    this.register.loading = true;
                    let passedCompanyId = companyconfig.getCompanyIdfromUrl();
                    let isLegacyMode = companyconfig.isLegacyMode(passedCompanyId);
                    loginapi
                        .doSMSRegister(
                            this.register.phone,
                            this.register.fullname,
                            passedCompanyId,
                            this.register.invitecode,
                            this.register.password,
                            isLegacyMode,
                            this.register.confirmationcode
                        )
                        .then(response => {
                            let msg = response.data.message;
                            if (msg === CREATED_ACCOUNT_SUCCESSFULLY) {
                                this.register.errors = this.$t(
                                    "LoginScreen.CREATED_ACCOUNT_SUCCESSFULLY"
                                );
                                this.register.errType = "success";
                                this.register.showerror = true;
                                this.register.loading = false;
                                this.resetforms();

                                //Track FB SignUp Event
                                if (window.fbq && typeof window.fbq !== 'undefined') {
                                    window.fbq('track', 'CompleteRegistration')
                                }
                            }
                            if (msg === SMS_SENT && !this.register.confirmationcode) {
                                this.register.errors = this.$t(
                                    "LoginScreen.SMS_SENT"
                                );
                                this.register.errType = "success";
                                this.register.showerror = true;
                                this.register.loading = false;
                                this.register.confirmationcodesent = true;
                            }
                        })
                        .catch(response => {
                          let msg = response.data.message;
                          console.log(response.data)
                          instance.register.errType = "error";
                          if(msg === 'UNABLE_TO_CREATE_ACCOUNT' && response.data.rdata && response.data.rdata.msg) {
                            instance.register.errors = response.data.rdata.msg;
                          } else if (msg === UNABLE_TO_CREATE_ACCOUNT) {
                            let errormsg = response.data.error;
                            if (
                                errormsg.company_url &&
                                errormsg.company_url[0] === NO_COMPANY_FOUND
                            ) {
                              this.register.errors = this.$t("Errors.NO_COMPANY_FOUND");
                            } else if (
                                errormsg.company_url &&
                                errormsg.company_url[0] === COMPANY_URL_IS_REQUIRED
                            ) {
                              this.register.errors = this.$t(
                                  "Errors.COMPANY_URL_IS_REQUIRED"
                              );
                            } else if (
                                errormsg.email &&
                                errormsg.email[0] === INCORRECT_EMAIL_FORMAT
                            ) {
                              this.register.errors = this.$t("Errors.INCORRECT_EMAIL_FORMAT");
                            } else if (
                                errormsg.email &&
                                errormsg.email[0] === EMAIL_IS_REQUIRED
                            ) {
                              this.register.errors = this.$t("Errors.EMAIL_IS_REQUIRED");
                            } else if (
                                errormsg.name &&
                                errormsg.name[0] === NAME_IS_REQUIRED
                            ) {
                              this.register.errors = this.$t("Errors.NAME_IS_REQUIRED");
                            }
                          } else if (msg === ACCOUNT_ALREADY_EXISTS) {
                            this.register.errors = this.$t("Errors.ACCOUNT_ALREADY_EXISTS");
                          } else if (msg === UNABLE_TO_VALIDATE_ACCOUNT) {
                            this.register.errors = this.$t("LoginScreen.UNABLE_TO_VALIDATE_ACCOUNT");
                          }
                          instance.register.showerror = true;
                          instance.register.loading = false;

                            console.log('wrong');
                            this.register.errType = "error";
                            this.register.showerror = true;
                            this.register.loading = false;

                        });
                }
            },
            goLegacyUrl (url) {
              let passedDeviceId = companyconfig.getDeviceIdfromUrl();
              url += (url.indexOf('?') < 0 ? '?' : '&') + 'd='+passedDeviceId;
              location.href = url;
            },
          getInvitationCodeText() {
            if (this.config.invite_code_required === "1") {
              return this.$t("LoginScreen.INVITECODE_REQUIRED");
            }
            return this.$t("LoginScreen.INVITECODE");
          }
        },
        components: {
            alert
        }
    };
</script>
<style>
    .border0px {
        border: 0px solid !important;
    }
    .v-btn.v-btn--loading .v-btn__loader {
        padding-left: 12%;
        color: green;
    }
    .v-btn__loader .v-progress-circular {
        height: 15px !important;
        width: 15px !important;
    }
    .pad2px {
        padding: 0 2px !important;
    }

    @media screen and (max-width: 768px) {
        .v-btn.v-btn--loading .v-btn__loader {
            padding-left: 30%;
            color: green;
        }
    }
</style>
